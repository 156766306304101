import React from 'react';
import PropTypes from 'prop-types';

import styles from './page.module.scss';

class Layout extends React.Component {
    render() {
        const { children } = this.props;

        return (
            <div className={styles.layout}>
                {children}
            </div>
        );
    }
}

Layout.propTypes = {
    children : PropTypes.oneOfType( [
        PropTypes.arrayOf( PropTypes.node ),
        PropTypes.node,
    ] ).isRequired,
};

export default Layout;
