import React from 'react';
import { Link } from "gatsby"

import CONTENT from '../../data/content';

import styles from './footer.module.scss';

class Footer extends React.Component {
    render() {
        const { lang, path } = this.props;

        return (
            <div className={styles.footer}>
                <div className={styles.links}>
                    {CONTENT.footerLinks.map((footerLink) =>
                        <div className={styles.link} key={footerLink[lang]}>
                            <a href={footerLink.link} rel="noopener noreferrer">{footerLink[lang]}</a>
                        </div>
                    )}
                    <div className={styles.link}>
                        <Link to={`/${path || ''}`}>DE</Link>
                    </div>
                    <div className={styles.link}>
                        <Link to={`/en${path || ''}`}>EN</Link>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <span>{`© ${new Date().getFullYear()} Perspective Software GmbH`}</span>
                </div>
            </div>
        )
    }
}

Footer.defaultProps = { lang : 'de' };

export default Footer;
