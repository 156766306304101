import React from 'react';

import styles from './contentHero.module.scss';

class ContentHero extends React.Component {
    render() {
        const { headline, subHeadline, heroVideoPlaceholder } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headline}>
                    <span>{headline}</span>
                </div>
                <div className={styles.subHeadline}>
                    <span>{subHeadline}</span>
                </div>
                <div className={styles.video} style={{background: `url("${heroVideoPlaceholder}")`, backgroundSize: 'cover'}} onClick={this.props.handleClick}>
                    <div className={styles.playButton}>
                        <img src="https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/play.png" alt="Play Video" />
                    </div>
                </div>
            </div>
        )
    }
}

export default ContentHero;
