import React from 'react';

import styles from './categoryCard.module.scss';

class Card extends React.Component {
    handleClick = () => {
        const { linkUrl, onClick, name } = this.props;

        if (onClick) {
            onClick(name);
        }

        if (window) {
            window.open(linkUrl, "_blank");
        }
    };

    render() {
        const { imgSrc, title, description } = this.props;

        return (
            <div className={styles.cardContainer} onClick={this.handleClick}>
                <div className={styles.innerContainer}>
                    <div className={styles.image}>
                        <img src={imgSrc} width="318px" />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            {title}
                        </div>
                        <div className={styles.description}>
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card;
