import React from 'react';

import styles from './navigationBar.module.scss';
import CONTENT from '../../data/content';

import Logo from './../../../static/logo.svg';
import HomeIcon from './../../../static/home.svg';
import BackIcon from './../../../static/back.svg';

class NavigationBar extends React.Component {
    handleClick = (e) => {
        e.preventDefault();

        if (window) {
            if (this.props.returnUrl && this.props.returnUrl !== '') {
                return window.location.href = this.props.returnUrl.replace('?returnUrl=', '').replace('/-/', '/#/')
            }

            return window.location.href = 'https://app.perspective.co/#/funnels';
        }
    };

    render() {
        const { lang, showFunnelLink, showBackButton } = this.props;

        return (
            <div className={styles.navigationBarContainer}>
                <div className={styles.innerContainer}>
                    <div className={styles.logo}>
                        <a href="https://www.perspective.co/" target="_blank" rel="noopener noreferrer">
                            <Logo />
                        </a>
                    </div>
                    {showFunnelLink && <div className={styles.linkContainer}>
                        <div className={styles.icon}>
                            <HomeIcon />
                        </div>
                        <div className={styles.link}>
                            <a href="https://app.perspective.co/#/funnels" target="_blank">
                                <span>{CONTENT.backToFunnels[lang]}</span>
                            </a>
                        </div>
                    </div>}
                    {showBackButton && <div className={styles.backLinkContainer}>
                        <span onClick={this.handleClick}>
                            <BackIcon /> <span className={styles.word}>{lang === 'de' ? 'Zurück' : 'Back'}</span>
                        </span>
                    </div>}
                </div>
            </div>
        )
    }
}

export default NavigationBar;
