import React from 'react';

import Vimeo from '@u-wave/react-vimeo';

import styles from './videoOverlay.module.scss';

class VideoOverlay extends React.Component {
    handleClose = () => {

    };

    render() {
        const { url } = this.props;

        return (
            <div className={styles.wrapper} onClick={this.props.onClose}>
                <div className={styles.container}>
                    <div className={styles.close} onClick={this.props.onClose}>
                        <img className="close_icon"
                             src="https://perspective.co/app/themes/perspective/images/cross.svg" />
                    </div>
                    <Vimeo
                        video={url}
                        autoplay
                    />
                </div>
            </div>
        )
    }
}

export default VideoOverlay;
