const CONTENT = {
    headline : {
        de : 'Entdecke hilfreiche Ressourcen für deinen Funnel Erfolg',
        en : 'Discover helpful resources for your funnel success',
    },
    backToFunnels : {
        de : 'Zu meinen Funnels',
        en : 'Go to my Funnels',
    },
    mainCards : [
        {
            imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/community.png',
            name   : 'community',
            title  : {
                de : 'Community entdecken',
                en : 'Be part of the community',
            },
            description : {
                de : 'Austausch mit über 2000 Unternehmern',
                en : 'Exchange with over 2000 entrepreneurs',
            },
            url : 'https://www.facebook.com/groups/7tncgrb',
        }, {
            imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/crashkurs.png',
            name   : 'webinar',
            title  : {
                de : 'Am Crash-Kurs teilnehmen',
                en : 'Free Funnel Crash Course',
            },
            description : {
                de : 'Wie du qualifizierte Kontakte gewinnst',
                en : 'Join our free funnel training',
            },
            url : 'https://strategie.perspective.co/crashkurs/',
        }, {
            imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/academy.png',
            name   : 'helpcenter',
            title  : {
                de : 'Zur Perspective Academy',
                en : 'Visit the Academy',
            },
            description : {
                de : 'Schulungsvideos, Hacks & Tutorials',
                en : 'Training Videos, Hacks & Tutorials',
            },
            url : 'https://intercom.help/perspective-funnels/de/articles/4130448-perspective-academy',
        },
    ],
    categories : [
        {
            title : {
                de : 'Auch interessant...',
                en : 'Also interesting...',
            },
            subCards : [
                {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/funnel-finder.png',
                    name    : 'funnel_finder',
                    title   : {
                        de : 'Kostenlose Vorlagen',
                        en : 'Free templates',
                    },
                    description : {
                        de : 'Working documents and more',
                        en : 'Arbeitsdokumente uvm.',
                    },
                    url : 'https://drive.google.com/open?id=1VldvikO3Jv5CfhJr7jLGHjsB2kSZv_mH',
                }, {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/worksheet.png',
                    name    : 'worksheets',
                    title   : {
                        de : 'Conversion-Hacks',
                        en : 'Conversion Hacks',
                    },
                    description : {
                        de : 'Jetzt entdecken',
                        en : 'Discover now',
                    },
                    url : 'https://www.youtube.com/watch?v=v5W-996sROw',
                }, {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/challenge.png',
                    name    : 'challenge',
                    title   : {
                        de : '7-Day Challenge',
                        en : '7-Day Challenge',
                    },
                    description : {
                        de : 'Lerne Neukundenakquise',
                        en : 'Learn to acquire customers',
                    },
                    url : 'https://www.youtube.com/playlist?list=PLEbn5C4ERLMt-cEskETtdHe3vknjk3-f0',
                },
            ],
        },
        {
            title : {
                de : 'Du liebst Perspective?',
                en : 'You love Perspective?',
            },
            subCards : [
                {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/teilen.png',
                    name    : 'share',
                    title   : {
                        de : 'Mit Freunden teilen',
                        en : 'Share with friends',
                    },
                    description : {
                        de : 'Spread the love!',
                        en : 'Spread the love!',
                    },
                    url : 'https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=https%3A%2F%2Ffacebook.com%2Fperspective.co&display=popup&ref=plugin&src=share_button',
                }, {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/rezension.png',
                    name    : 'review',
                    title   : {
                        de : 'Schreib eine Rezension',
                        en : 'Write a review',
                    },
                    description : {
                        de : 'Verrate uns deine Meinung',
                        en : 'Tell us what you think',
                    },
                    url : 'https://www.facebook.com/pg/perspective.co/reviews/',
                }, {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/affiliate.png',
                    name    : 'affiliate',
                    title   : {
                        de : 'Affiliate Partner werden',
                        en : 'Become an affiliate partner',
                    },
                    description : {
                        de : 'Mach andere erfolgreich',
                        en : 'Make others successful',
                    },
                    url : 'https://partner.perspective.co/start/',
                },
            ],
        },
        {
            title : {
                de : 'Genieße den Inhalt:',
                en : 'Enjoy the contents:',
            },
            subCards : [
                {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/youtube.png',
                    name    : 'youtube',
                    title   : {
                        de : 'Youtube',
                        en : 'Youtube',
                    },
                    description : {
                        de : 'Aktuelle Videos',
                        en : 'Our latest videos',
                    },
                    url : 'https://www.youtube.com/channel/UC9amSeTykQ4eAKtCs3g_tQw',
                }, {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/linkedin.png',
                    name    : 'linkedin',
                    title   : {
                        de : 'LinkedIn',
                        en : 'LinkedIn',
                    },
                    description : {
                        de : 'Let’s connect',
                        en : 'Let’s connect',
                    },
                    url : 'https://linkedin.com/company/perspective-co',
                }, {
                    iconSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/instagram.png',
                    name    : 'instagram',
                    title   : {
                        de : 'Instagram',
                        en : 'Instagram',
                    },
                    description : {
                        de : 'Entdecke unseren Alltag',
                        en : 'Discover our everyday life',
                    },
                    url : 'https://www.instagram.com/perspective.digital/',
                },
            ],
        },
    ],
    templateCards : [
        {
            imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/template_card_button.png',
            name   : 'button_link',
            title  : {
                de : 'Verlinkt als Button',
                en : 'Be part of the Community',
            },
            description : {
                de : 'Austausch mit 2000 Unternehmern',
                en : 'Exchange with over 2000 entrepreneurs',
            },
            url : 'https://www.facebook.com/groups/7tncgrb',
        }, {
            imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/template_card_button.png',
            name   : 'button_link',
            title  : {
                de : 'Per iFrame auf Seite',
                en : 'Be part of the Community',
            },
            description : {
                de : 'Austausch mit 2000 Unternehmern',
                en : 'Exchange with over 2000 entrepreneurs',
            },
            url : 'https://www.facebook.com/groups/7tncgrb',
        }, {
            imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/template_card_button.png',
            name   : 'button_link',
            title  : {
                de : 'Als Menüpunkt ergänzen',
                en : 'Be part of the Community',
            },
            description : {
                de : 'Austausch mit 2000 Unternehmern',
                en : 'Exchange with over 2000 entrepreneurs',
            },
            url : 'https://www.facebook.com/groups/7tncgrb',
        },
    ],
    contentPages : {
        website : {
            headline : {
                de : 'Auf Webseite einbinden',
                en : 'Embed on website',
            },
            heroText : {
                de : 'Baue deinen Mobile-Funnel mit wenigen Klicks auf deiner Webseite ein und verwandle deine Webseite in eine Leadgenerierungsmaschine.',
                en : 'Install your mobile funnel on your website with just a few clicks and turn your website into a lead generation engine.',
            },
            heroVideoPlaceholderSrc : 'https://images.ctfassets.net/eu0fjb9j6b1i/6WabUByy9MIos10uS7CQkd/ba52dc62158fcf2f7ee1b4cc197eed80/website.png',
            heroVideoLink           : 'https://vimeo.com/387450063',
            subHeadline             : {
                de : 'Weitere Ressourcen, die dir weiterhelfen werden:',
                en : 'Other resources that will help you:',
            },
            cards : [
                {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/funnel_overview.png',
                    name   : 'download_funnel_overview',
                    title  : {
                        de : 'Funnel-Übersicht herunterladen',
                        en : 'Download Funnel Overview',
                    },
                    description : {
                        de : 'Erhalte eine Übersicht von allen Funnel-Vorlagen und lasse dich von den verschiedenen Möglichkeiten inspirieren.',
                        en : 'Get an overview of all funnel templates and be inspired by the different possibilities.',
                    },
                    url : 'https://drive.google.com/file/d/1qYKydnfq-tGrm_6PFYZaiw0kE-6x9zMs/view?usp=sharing',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_community.png',
                    name   : 'community',
                    title  : {
                        de : 'Zur Perspective Community',
                        en : 'Visit our Community',
                    },
                    description : {
                        de : 'Trete der Perspective Community auf Facebook bei und erhalte Unterstützung von Tausenden anderen Unternehmern.',
                        en : 'Join the Perspective Community on Facebook and get support from thousands of other entrepreneurs.',
                    },
                    url : 'https://www.facebook.com/groups/perspectiveofficial/',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/live+training+michael.png',
                    name   : 'live_training',
                    title  : {
                        de : 'Kostenloser Funnel Crashkurs',
                        en : 'Free Funnel Crash Course',
                    },
                    description : {
                        de : 'Erfahre in nur 60 Minuten, wie du online qualifizierte Kontakte & Neukunden gewinnst - so schnell wie nie zuvor!',
                        en : 'Join one of our weekly free funnel trainings.',
                    },
                    url : 'https://strategie.perspective.co/crashkurs/',
                },
            ],
        },
        facebook : {
            headline : {
                de : 'Auf Facebook und Instagram bewerben',
                en : 'Promote on Facebook and Instagram',
            },
            heroText : {
                de : 'Schalte Werbeanzeigen auf Facebook und Instagram und generiere unbegrenzte Besucherströme für deinen Mobile-Funnel.',
                en : 'Place ads on Facebook and Instagram and generate unlimited traffic for your mobile funnel.',
            },
            heroVideoPlaceholderSrc : 'https://images.ctfassets.net/eu0fjb9j6b1i/3uv2fXvnQ5xK9AiHNh8yBj/74bfcffe83d021e67493ab68095be3a8/fb_und_insta.png',
            heroVideoLink           : 'https://vimeo.com/387452663',
            subHeadline             : {
                de : 'Weitere Ressourcen, die dir weiterhelfen werden:',
                en : 'Other resources that will help you:',
            },
            cards : [
                {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_ad_library.png',
                    name   : 'facebook_ads_library',
                    title  : {
                        de : 'Zur Facebook-Werbebibliothek',
                        en : 'Visit the Facebook advertising library',
                    },
                    description : {
                        de : 'Stöbere durch die Anzeigen deiner Mitbewerber und lass\' dich von Anzeigen-Ideen anderer Unternehmen inspirieren.',
                        en : 'Browse through the ads of your competitors and be inspired by ad ideas of other companies.',
                    },
                    url : 'https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=DE&impression_search_field=has_impressions_lifetime',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_ad_manager.png',
                    name   : 'facebook_ads_manager',
                    title  : {
                        de : 'Zum Werbeanzeigenmanager',
                        en : 'Visit the ad manager',
                    },
                    description : {
                        de : 'Hier kommst du direkt in den Facebook Werbeanzeigenmanager und kannst mit deiner ersten Facebook Anzeige starten.',
                        en : 'Here you can go directly to the Facebook ad manager and start with your first Facebook ad. ',
                    },
                    url : 'http://business.facebook.com/',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_ad_tutorial-1.png',
                    name   : 'learn_facebook_essentials',
                    title  : {
                        de : 'Facebook-Grundlagen lernen',
                        en : 'Learn Facebook basics',
                    },
                    description : {
                        de : 'Erfahre wie du deine erste Facebook-Anzeige aufsetzt in folgendem, ausführlichen Tutorial-Blogpost. ',
                        en : 'Learn how to set up your first Facebook ad in the following in-depth tutorial blog post.',
                    },
                    url : 'https://perspective.co/anleitung-facebook-anzeige/',
                },
            ],
        },
        google : {
            headline : {
                de : 'Auf Google bewerben',
                en : 'Promote on Google',
            },
            heroText : {
                de : 'Erstelle Anzeigen auf Google und leite Personen, die nach deinem Produkt oder deiner Dienstleistung suchen direkt auf deinen Mobile-Funnel.',
                en : 'Create ads on Google and link people directly to your mobile funnel if they are searching for your product or service.',
            },
            heroVideoPlaceholderSrc : 'https://images.ctfassets.net/eu0fjb9j6b1i/2SEBB77Klsja5sxTjWCGjF/c9d9a1b4c3d73a0b6bde70602772eb68/google.png',
            heroVideoLink           : 'https://vimeo.com/387452480',
            subHeadline             : {
                de : 'Weitere Ressourcen, die dir weiterhelfen werden:',
                en : 'Other resources that will help you:',
            },
            cards : [
                {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/google_academy.png',
                    name   : 'google_academy',
                    title  : {
                        de : 'Zur Google Academy',
                        en : 'Visit Google Academy',
                    },
                    description : {
                        de : 'Entdecke hilfreiche, kostenlose Online-Kurse zum Thema Online-Marketing und Online-Werbung in der Google Academy.',
                        en : 'Discover helpful, free online courses on online marketing and online advertising at the Google Academy',
                    },
                    url : 'https://learndigital.withgoogle.com/digitalworkshop-at',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/google_ads.png',
                    name   : 'google_adwords',
                    title  : {
                        de : 'Zu Google AdWords',
                        en : 'Visit Google AdWords',
                    },
                    description : {
                        de : 'Hier kommst du direkt zu Google AdWords und kannst mit deiner ersten Anzeige für deinen Mobile-Funnel starten.',
                        en : 'Here you get directly to Google AdWords and can start with your first ad for your Mobile-Funnel.',
                    },
                    url : 'https://adwords.google.de/',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_community.png',
                    name   : 'perspective_community',
                    title  : {
                        de : 'Zur Perspective Community',
                        en : 'Visit our Community',
                    },
                    description : {
                        de : 'Trete der Perspective Community auf Facebook bei und erhalte Unterstützung von Tausenden anderen Unternehmern.',
                        en : 'Join the Perspective Community on Facebook and get support from thousands of other entrepreneurs.',
                    },
                    url : 'https://www.facebook.com/groups/perspectiveofficial/',
                },
            ],
        },
        qr : {
            headline : {
                de : 'Einen QR-Code benutzen',
                en : 'Using a QR Code',
            },
            heroText : {
                de : 'Gib deinen Kunden die Möglichkeit über das Scannen eines QR-Codes direkt auf deinen Mobile-Funnel zu gelangen (z.B. in einer Präsentation auf einer Veranstaltung).',
                en : 'Give your customers the possibility to get directly to your mobile funnel by scanning a QR code (e.g. in a presentation at an event).',
            },
            heroVideoPlaceholderSrc : 'https://images.ctfassets.net/eu0fjb9j6b1i/2YdBsSV5hOM19T1nhq5DUF/d0157700da3404703bf94c926a9c70a3/qr.png',
            heroVideoLink           : 'https://vimeo.com/387450490',
            subHeadline             : {
                de : 'Weitere Ressourcen, die dir weiterhelfen werden:',
                en : 'Other resources that will help you:',
            },
            cards : [
                {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/qr_code_slide.png',
                    name   : 'sample_presentation',
                    title  : {
                        de : 'Beispiel für Präsentation ansehen',
                        en : 'View example of presentation',
                    },
                    description : {
                        de : 'Baue den Barcode z.B. auf eine Folie deiner Präsentation ein. Hier findest du eine Beispiel-Folie.',
                        en : 'Install the QR Code e.g. on a slide of your presentation. Here you can find an example slide.',
                    },
                    url : 'https://drive.google.com/file/d/1_-FH7bfzvD8XFDuXXQuw4rh4AWkk4pQW/view?usp=sharing',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_community.png',
                    name   : 'perspective_community',
                    title  : {
                        de : 'Zur Perspective Community',
                        en : 'Visit our Community',
                    },
                    description : {
                        de : 'Trete der Perspective Community auf Facebook bei und erhalte Unterstützung von Tausenden anderen Unternehmern.',
                        en : 'Join the Perspective Community on Facebook and get support from thousands of other entrepreneurs.',
                    },
                    url : 'https://www.facebook.com/groups/perspectiveofficial/',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/live_tutorial.png',
                    name   : 'live_training',
                    title  : {
                        de : 'Am LIVE-Training teilnehmen',
                        en : 'Participate in LIVE Training',
                    },
                    description : {
                        de : 'Jeden Donnerstag um 10 Uhr findet unser LIVE-Training statt. Hier kannst du alle deine Fragen stellen.',
                        en : 'Every Thursday at 10 o\'clock our LIVE training takes place. Here you can ask all your questions.',
                    },
                    url : 'https://strategie.perspective.co/live-training/',
                },
            ],
        },
        mail : {
            headline : {
                de : 'Per E-Mail oder Messenger bewerben',
                en : 'Promote by email or messenger',
            },
            heroText : {
                de : 'Sende einen Newsletter per E-Mail oder Facebook Messenger und generiere qualifizierte Leads aus deinen bestehenden Kontakten.',
                en : 'Send a newsletter via email or Facebook Messenger and generate qualified leads from your existing contacts.',
            },
            heroVideoPlaceholderSrc : 'https://images.ctfassets.net/eu0fjb9j6b1i/2DPaARYXM5OOyPKjsww3X7/cee29fcf435226dd0be812ae0f0c6d4b/messenger.png',
            heroVideoLink           : 'https://vimeo.com/387450666',
            subHeadline             : {
                de : 'Weitere Ressourcen, die dir weiterhelfen werden:',
                en : 'Other resources that will help you:',
            },
            cards : [
                {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/fb_community.png',
                    name   : 'perspective_community',
                    title  : {
                        de : 'Zur Perspective Community',
                        en : 'Visit our Community',
                    },
                    description : {
                        de : 'Trete der Perspective Community auf Facebook bei und erhalte Unterstützung von Tausenden anderen Unternehmern.',
                        en : 'Join the Perspective Community on Facebook and get support from thousands of other entrepreneurs.',
                    },
                    url : 'https://www.facebook.com/groups/perspectiveofficial/',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/funnel_overview.png',
                    name   : 'download_funnel_overview',
                    title  : {
                        de : 'Funnel-Übersicht herunterladen',
                        en : 'Download Funnel overview',
                    },
                    description : {
                        de : 'Erhalte eine Übersicht von allen Funnel-Vorlagen und lasse dich von den verschiedenen Möglichkeiten inspirieren.',
                        en : 'Get an overview of all funnel templates and be inspired by the different possibilities.',
                    },
                    url : 'https://drive.google.com/file/d/1qYKydnfq-tGrm_6PFYZaiw0kE-6x9zMs/view?usp=sharing',
                }, {
                    imgSrc : 'https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/content_pages/live_tutorial.png',
                    name   : 'live_training',
                    title  : {
                        de : 'Am LIVE-Training teilnehmen',
                        en : 'Participate in LIVE Training',
                    },
                    description : {
                        de : 'Jeden Donnerstag um 10 Uhr findet unser LIVE-Training statt. Hier kannst du alle deine Fragen stellen.',
                        en : 'Every Thursday at 10 o\'clock our LIVE training takes place. Here you can ask all your questions.',
                    },
                    url : 'https://strategie.perspective.co/live-training/',
                },
            ],
        },
    },
    footerLinks : [
        {
            name : 'impressum',
            de   : 'Impressum',
            en   : 'Impressum',
            link : 'https://perspective.co/impressum',
        },
        {
            name : 'privacy',
            de   : 'Datenschutzerklärung',
            en   : 'Privacy Policy',
            link : 'https://perspective.co/privacy',
        },
        {
            name : 'agb',
            de   : 'AGB',
            en   : 'Terms of Use',
            link : 'https://perspective.co/agb',
        },
    ],
};

export default CONTENT;
