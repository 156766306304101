import React from 'react';

import styles from './mastermindBanner.module.scss';

const content = {
    de : {
        headline    : 'Deine kostenlose Academy',
        description : 'Du willst lernen, wie du mit Perspective spielend einfach Funnels bauen kannst? Wir haben für Dich kostenlose Online-Kurse in der Perspective Academy!',
        button      : 'Academy öffnen',
    },
    en : {
        headline    : 'Your free Academy',
        description : 'You want to learn how you can easily create funnels with Perspective? We have a free online course in the Perspective Academy!',
        button      : 'Open Academy',
    },
};

class MasterMindBanner extends React.Component {
    handleButtonClick = () => {
        if ( window ) {
            window.open( 'https://intercom.help/perspective-funnels/de/articles/4130448-perspective-academy', '_blank' );
        }
    };

    render() {
        const { lang } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.inner}>
                    <div className={styles.colorBar}>
                        <img src="https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/color_bar_purple.png" />
                    </div>
                    <div className={styles.icon}>
                        <img src="https://perspective-imgix.s3.eu-central-1.amazonaws.com/resource_center/rect.png" alt="Perspective Academy" />
                    </div>
                    <div className={styles.headline}>
                        <span>{content[ lang ].headline}</span>
                    </div>
                    <div className={styles.description}>
                        <span>{content[ lang ].description}</span>
                    </div>
                    <div className={styles.ctaContainer}>
                        <button className={styles.cta} onClick={this.handleButtonClick}>{content[ lang ].button}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default MasterMindBanner;
